
export class CustomConstants {

    // Version
    public static ADMIN_SOFTWARE_VERSION: string = "v0.1.2";

    // Admin
    public static ADMIN_SOFTWARE_NAME: string = "THERMO•LINK";
    public static ADMIN_SOFTWARE_ENDPOINT: string = "https://admin.thermolink.chillermen.com";
    public static MONITORING_SOFTWARE_URL: string = "https://thermolink.chillermen.com";

    // Backend
    public static API_ENDPOINT: string = "https://us-central1-jas-chillermen.cloudfunctions.net/v2";
    public static SERVER_UIDS: string[] = [
        "jas-data-server",
        "jas-schedule-server",
        "jas-billing-server",
        "jot-server"
    ];

    // (Automatic billing)
    public static STRIPE_OEM_CONNECT_ID: string = "acct_1PlcqUC2fXOWIhn2";
    public static STRIPE_PRICE_CHILLER_CELLULAR: string = "price_1QXT27C2fXOWIhn2kAbJ0P11";

    // Return the stripe fee amount for a given price ID (100 = $1)
    public static getJASFeeForPriceID(priceID: string | null): number {
        if (priceID === this.STRIPE_PRICE_CHILLER_CELLULAR) {
            // Cellular chiller service
            // $300
            return (300 * 100);
        }
        // Default to none
        return 0;
    }

}
